.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #1677FF;
}

.ant-carousel .slick-dots li button {
  background: #001529
}

.pdf-file {
  width: 200px;
  height: 100px;
}

.column-pdf-text:hover{
  cursor: pointer;
}

.car-div {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.p-car {
  font-size: 20px;
}

.site-layout-content {
  box-shadow: 6px 5px 20px #ccc;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-layout {
  background: transparent;
  background-image: url("./images/main-bg-img.png");
  background-position:center;
  background-size: cover;
}

.cabinet-search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cabinet {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: flex-end;
}


.cabinet-text {
  color: #A6ADB4;
  font-weight: 700;
}

.cabinet-text:hover{
  color: #E93131 !important;
}
.cabinet-icon:hover{
  color: red !important
}


.input-search {
  width: 10px;
}

.gutter-row {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.doubleMain-elem {
  width: 280px;
  height: 150px;
  border: 1px solid rgb(117, 115, 115);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
}

.icon-div {
  position: relative;
  bottom: 45px;
}

.text-icon {
  position: relative;
  bottom: 30px;
  font-weight: 600;
  font-size: 20px;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected {
  background-color: #E93131;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected:hover {
  background-color: #E93131;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected:hover {
  background-color: transparent;
}

.mail-to{
  color: #E93131 !important;
}

.mail-to:hover{
  color: rgb(245, 53, 53) !important;
  font-weight: 650;
}

.carousel-by-main-page {
  margin-bottom: 40px;
}

.car-main-con {
  position: relative;
  bottom: 400px;
  text-align: left;
  padding-left: 50px;
}

.custom-link {
  text-decoration: none;
  color: #001529;
}

.custom-link:hover {
  text-decoration: none;
  color: rgb(206, 17, 38);
  text-decoration: underline;
  font-style: italic;
}

/* .s-icon:hover {
  color: white;
  border-radius: 2px;
  background: black;
  transition: 0.3s ease-in-out;
} */

@media (max-width: 1580px) {
  .ant-menu-horizontal {
    width: 800px !important;
  }
}

@media (max-width: 1216px) {
  .ant-menu-horizontal {
    width: 600px !important;
  }
}